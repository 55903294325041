<template lang="pug">

q-header.bg-grey-2.q-px-lg(bordered)
  .q-py-lg.text-subtitle1.row.items-center
    span.q-mr-xs
      q-icon(name="science" size="md" color="black")
    span.text-black.text-bold CHeKT Lab

  q-separator

  .q-py-lg
    .text-h4.text-black Visual Automation
    .q-py-xs
    .text-body1.text-grey-7 Automation tools for video monitoring service providers

</template>

<style>
</style>

<script>
export default {
}
</script>
