<template lang="pug">

q-page
  q-list
    q-item.q-pa-md(
      :to="v.to"
      v-for="v in items" clickable)
      q-item-section(avatar)
        template(v-if="v.avatarType === 'text'")
          q-avatar(:color="v.avatarColor" text-color="white" size="50px")
            span(style="font-size:14px;font-weight:bold;") {{v.avatarText}}
        template(v-else-if="v.avatarType === 'icon'")
          q-avatar(:color="v.avatarColor" text-color="white" size="50px" :icon="v.avatarIcon")
        template(v-else-if="v.avatarType === 'image'")
          q-avatar(:color="v.avatarColor" text-color="white" size="50px")
            img(:src="v.avatarImage" style="width:75%;height:75%;")
      q-item-section
        q-item-label.text-h6 {{v.title}}
        q-item-label(caption line="2") {{v.caption}}

</template>

<style>
</style>

<script>
export default {
  data: function () {
    return {
      items: [
        {
          title: "Video AI",
          caption: "Detect and classify\nmoving objects",
          to: "/video-ai",
          avatarType: "icon",
          avatarIcon: "psychology",
          avatarColor: "blue",
        },
        {
          title: "Threat Level Rules",
          caption: "Automation rules to define the threat level of an incident",
          avatarType: "text",
          avatarText: "TL3",
          avatarColor: "red",
        },
        {
          title: "Report",
          caption: "Automate to build a beautiful incident report",
          avatarType: "icon",
          avatarIcon: "description",
          avatarColor: "grey-9",
        },
        {
          title: "Guide",
          caption: "Run guided video monitoring operations",
          avatarType: "image",
          avatarImage: "/img/guided.png",
          avatarColor: "amber",
        },
      ]
    }
  }
}
</script>
