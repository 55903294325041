<template lang="pug">

q-footer.bg-grey-2.q-px-lg(bordered)
  .q-py-lg
    .text-black.text-bold CHeKT Inc.

</template>

<style>
</style>

<script>
export default {
}
</script>
