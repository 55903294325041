<template lang="pug">

q-layout(view="hhh LpR fff")
  //- Layout
  Header
  Footer

  //- Body
  q-page-container
    Body

</template>

<style>
</style>

<script>
import Header from "./_comps/Header.vue";
import Footer from "./_comps/Footer.vue";
import Body from "./_comps/Body.vue";

export default {
  components: {
    Header,
    Footer,
    Body,
  },
}
</script>
